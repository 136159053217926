import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const GovernanceRiskManagement = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="GovernanceRiskManagement"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">Organizations are facing new challenges across the spectrum of GRC requirements as a result of the adoption of new technologies and the ever-increasing complexity of legal and regulatory compliances. As a result, organizations are now more focused on compliance and keen to invest in services to manage IT risk and the ever-changing compliance landscape.  </p>
                        <p className="mt-3">A strong GRC program can help organizations minimize threats and risks to their businesses and also provide a safeguard in ensuring that corporate accountability is effectively planned, designed, and implemented across all work platforms.</p>
                        <p className="mt-2">TEQT's GRC (Governance, Risk Management, and Compliance) services are designed to reduce risk exposure, improve governance, deliver proactive compliance management through an integrated and automated GRC system. </p>
                        <p className="mt-2">TEQT's platform-based GRC service is powered by industry-leading GRC products, experienced resources and TEQT's expertise in the business and technology domain.</p>
                        <p className="mt-2">TEQT's GRC platform is designed to deliver personalized services to address specific client requirements and improve their risk and compliance posture in a short time period while providing full control and visibility to the clients on the evolving risk and compliance dynamics of their environment.</p>


                        <h6>TEQT Governance Risk Management and Compliance services comprise of</h6>
                        <ul className="circle-list">
                            <li className="mt-2"><span className="text-black">Audit Management:</span>Streamline evidence collection and optimize resources to increase productivity using risk data.</li>
                            <li className="mt-2"><span className="text-black">Vendor Risk Management:</span>Identification, assessment, and categorization of vendors based on associated risk, simplify communication and collaboration with third-parties. </li>
                            <li className="mt-2"><span className="text-black">Policy Management:</span>Common controls framework to detect changes, business risks and delivers real-time insight into compliance.</li>
                            <li className="mt-2"><span className="text-black">Enterprise Risk Management:</span>Continuous monitoring, identification, and prioritization of risks based on business criticality to reduce reaction time and improve decision making.</li>
                            <li className="mt-2"><span className="text-black">Operational Risk Management:</span>Identity, analyze, manage, and report on operational risk at all levels in a risk hierarchy including at the topmost or enterprise level.</li>

                        </ul>

                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query GovernanceRiskManagementPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

GovernanceRiskManagement.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default GovernanceRiskManagement;
